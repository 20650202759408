// components/Contactos.js
import React, { useState } from 'react';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';

export default function Contactos() {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="relative p-4 rounded-md bg-black bg-opacity-50">
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-semibold mb-6">
        Entre em Contacto Connosco
      </h2>
      <div className="flex flex-wrap lg:flex-nowrap justify-center gap-4 sm:gap-6 md:gap-8 lg:gap-10">
        {/* Email */}
        <div className="relative group">
          <a
            href="mailto:Geral@loudbeats.pt"
            className="hover:text-yellow-300 transition-colors duration-300 flex items-center"
          >
            <EnvelopeIcon className="h-5 w-5 sm:h-6 sm:w-6 lg:h-8 lg:w-8 mr-2" />
            <span>Email</span>
          </a>
          <div className="absolute bottom-full mb-2 hidden group-hover:block bg-gray-800 text-white text-sm rounded p-2">
            Geral@loudbeats.pt
          </div>
        </div>

        {/* Phone */}
        <div className="relative group">
          <button
            onClick={() => copyToClipboard('+351 919 700 698')}
            className="hover:text-yellow-300 transition-colors duration-300 flex items-center"
          >
            <PhoneIcon className="h-5 w-5 sm:h-6 sm:w-6 lg:h-8 lg:w-8 mr-2" />
            <span>Telefone</span>
          </button>
          <div className="absolute bottom-full mb-2 hidden group-hover:block bg-gray-800 text-white text-sm rounded p-2">
            +351 919 700 698
          </div>
          {copied && (
            <div className="absolute bottom-full mb-2 bg-gray-800 text-white text-sm rounded p-2">
              Copied!
            </div>
          )}
        </div>

        {/* Social Media Icons */}
        <SocialMediaIcon
          href="https://www.instagram.com/loudbeats_eventservices/"
          tooltip="@loudbeats"
          icon={InstagramIcon}
          label="Instagram"
        />
        <SocialMediaIcon
          href="https://www.facebook.com/profile.php?id=100057435370532"
          tooltip="/loudbeats"
          icon={FacebookIcon}
          label="Facebook"
        />
        <SocialMediaIcon
          href="https://www.linkedin.com/in/loudbeats-events-services-5bab01339/"
          tooltip="/company/loudbeats"
          icon={LinkedInIcon}
          label="LinkedIn"
        />
      </div>
    </div>
  );
}

// Helper component for social media icons
function SocialMediaIcon({ href, tooltip, icon: Icon, label }) {
  return (
    <div className="relative group">
      <a
        href={href}
        className="hover:text-yellow-300 transition-colors duration-300 flex items-center"
      >
        <Icon className="h-5 w-5 sm:h-6 sm:w-6 lg:h-8 lg:w-8 mr-2" />
        <span>{label}</span>
      </a>
      <div className="absolute bottom-full mb-2 hidden group-hover:block bg-gray-800 text-white text-sm rounded p-2">
        {tooltip}
      </div>
    </div>
  );
}

// Social Media Icons Components
function InstagramIcon({ className }) {
  return (
    <svg className={className} fill="currentColor" viewBox="0 0 24 24">
      <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
    </svg>
  );
}

function FacebookIcon({ className }) {
  return (
    <svg className={className} fill="currentColor" viewBox="0 0 24 24">
      <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
    </svg>
  );
}

function LinkedInIcon({ className }) {
  return (
    <svg className={className} fill="currentColor" viewBox="0 0 24 24">
      <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.761 0 5-2.239 5-5v-14c0-2.761-2.239-5-5-5zm-11.75 20h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.784-1.75-1.75s.784-1.75 1.75-1.75 1.75.784 1.75 1.75-.784 1.75-1.75 1.75zm13.25 12.268h-3v-5.5c0-1.378-.028-3.152-1.922-3.152-1.922 0-2.218 1.5-2.218 3.052v5.6h-3v-11h2.888v1.5h.041c.403-.765 1.388-1.568 2.857-1.568 3.053 0 3.618 2.008 3.618 4.623v6.445z" />
    </svg>
  );
}