// components/Descricao.js
import React, { useState, useEffect } from 'react';
import { FaBeer, FaCalendarAlt, FaUtensils, FaRecycle, FaToolbox, FaShoppingCart, FaTruck, FaUsers } from 'react-icons/fa';

export default function Descricao() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 640); // sm breakpoint
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const servicos = [
    {
      nome: "Concessão e Exploração de Bar",
      nomeShort: "Bar",
      icone: <FaBeer className="text-red-500" />
    },
    {
      nome: "Produção de Eventos",
      nomeShort: "Eventos",
      icone: <FaCalendarAlt className="text-blue-500" />
    },
    {
      nome: "Serviço Alimentar",
      nomeShort: "Alimentação",
      icone: <FaUtensils className="text-green-500" />
    },
    {
      nome: "Serviço Eco-Copos",
      nomeShort: "Eco-Copos",
      icone: <FaRecycle className="text-yellow-500" />
    },
    {
      nome: "Aluguer de Material",
      nomeShort: "Material",
      icone: <FaToolbox className="text-purple-500" />
    },
    {
      nome: "Venda de Produtos",
      nomeShort: "Produtos",
      icone: <FaShoppingCart className="text-pink-500" />
    },
    {
      nome: "Serviço Logístico",
      nomeShort: "Logística",
      icone: <FaTruck className="text-orange-500" />
    },
    {
      nome: "Gestão de Recursos Humanos",
      nomeShort: "RH",
      icone: <FaUsers className="text-teal-500" />
    },
  ];

  if (isMobile) {
    return (
      <div className="flex flex-col items-center justify-center py-2">
<h2 className="text-2xl md:text-3xl lg:text-4xl font-extrabold text-white mb-6 text-center px-6 py-3 bg-black/50 rounded-lg shadow-text shadow-lg backdrop-blur">
Transformamos o seu evento com serviços personalizados e excelência
</h2>
        <div className="mt-6">
        <ul className="grid grid-cols-2 gap-4 text-white">
          {servicos.map((servico, index) => (
           <li
           key={index}
           className="flex flex-col items-center p-2 bg-gray-800 rounded-md hover:bg-gray-700 transition"
         >
           <div className="text-2xl mb-2">
             {servico.icone}
           </div>
           <span className="text-sm text-center">{servico.nomeShort}</span>
         </li>
          ))}
        </ul>
      </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center max-w-2xl  px-4 py-8">
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-extrabold text-white mb-6 text-center px-6 py-3 bg-black/30 rounded-lg shadow-text backdrop-blur">
        Transformamos o seu evento com serviços personalizados e excelência
      </h2>
      <ul className="grid grid-cols-1 sm:grid-cols-2 gap-4 max-w-3xl text-white">
        {servicos.map((servico, index) => (
          <li
            key={index}
            className="flex items-center p-4 bg-gray-800 rounded-lg shadow-md hover:bg-gray-700 transition"
          >
            <div className="mr-3 text-2xl">
              {servico.icone}
            </div>
            <span className="text-lg">{servico.nome}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}
