//components/EmConstrucao.js

import React, { useState, useEffect, useRef } from 'react';
import { FaHammer, FaTools, FaHardHat } from 'react-icons/fa';

export default function EmConstrucao() {
  const [progress, setProgress] = useState(0);
  const [iconIndex, setIconIndex] = useState(0);
  const constructionIcons = [FaHammer, FaTools, FaHardHat];
  const textRef = useRef(null);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => (oldProgress >= 100 ? 0 : oldProgress + 1));
    }, 100);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const iconTimer = setInterval(() => {
      setIconIndex((prev) => (prev + 1) % constructionIcons.length);
    }, 1000);
    return () => clearInterval(iconTimer);
  }, [constructionIcons.length]); 

  const Icon = constructionIcons[iconIndex];

  return (
    <div className="w-full max-w-lg construction-container p-2 sm:p-4 rounded-lg mx-auto"
      style={{
        background: `repeating-linear-gradient(
          45deg,
          #ffd700,
          #ffd700 8px,
          #000000 8px,
          #000000 16px
        )`
      }}>
      <div className="bg-zinc-900 p-2 sm:p-4 rounded-lg">
        <div className="flex items-center justify-center mb-2 sm:mb-3">
          <Icon className="w-6 h-6 sm:w-8 sm:h-8 mr-2 text-yellow-400 animate-bounce" />
          <h1 
            ref={textRef}
            className="text-2xl sm:text-3xl font-bold text-yellow-400"
          >
            Em Construção
          </h1>
          <Icon className="w-6 h-6 sm:w-8 sm:h-8 ml-2 text-yellow-400 animate-bounce" />
        </div>
        
        <div className="mx-auto" style={{ width: textRef.current?.offsetWidth || 'auto' }}>
          <div className="w-full bg-zinc-800 rounded-full h-2 mb-2 sm:mb-3">
            <div 
              className="bg-yellow-400 h-2 rounded-full transition-all duration-300"
              style={{ width: `${progress}%` }}
            />
          </div>
        </div>
        <p className="text-yellow-400 italic text-xs sm:text-sm text-center">
          Prometemos ser breves
        </p>
      </div>
    </div>
  );
}